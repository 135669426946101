.content-box {
  padding: 25px;
  background: rgba(20, 20, 20, 0.8);
  border-radius: 15px;
  text-align: center;
  color: white;
  max-width: 600px;
  margin: 30px auto;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.3),
    inset 0 2px 10px rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  animation: fadeIn 0.5s ease-out;
  transition: all 0.3s ease;
  width: 90%;
}

.content-box h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #00b881, #0088b8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.content-box p {
  line-height: 1.6;
  margin-bottom: 15px;
  font-size: 1rem;
}

.content-box a {
  color: #00b881;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;
}

.content-box a:hover {
  color: #00d4a4;
  text-shadow: 0 0 5px rgba(0, 212, 164, 0.5);
}

.content-box a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background: linear-gradient(90deg, #00b881, #0088b8);
  transition: width 0.3s ease;
}

.content-box a:hover:after {
  width: 100%;
}

/* Project card styling */
.project-card {
  background: rgba(30, 30, 30, 0.6);
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.project-card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #00b881;
  text-shadow: 0 0 10px rgba(0, 184, 129, 0.3);
}

.project-features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0;
  text-align: left;
  padding-left: 10px;
}

.project-features span {
  font-size: 0.9rem;
  opacity: 0.9;
  display: flex;
  align-items: center;
}

.project-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #00b881;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 15px;
  font-size: 1rem;
  padding: 4px;
}

.project-link:hover {
  color: #00d4a4;
  transform: translateY(-2px);
}

.project-link box-icon {
  transition: transform 0.3s ease;
  margin-right: 4px;
}

.project-link:hover box-icon {
  transform: translateX(2px);
}

.more-projects {
  font-style: italic;
  opacity: 0.8;
  margin-top: 20px;
}

.contact-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 12px;
  transition: transform 0.2s ease;
}

.contact-item:hover {
  transform: translateY(-2px);
}

.contact-item a {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.contact-item a:hover {
  background: rgba(0, 184, 129, 0.2);
  transform: scale(1.1);
}

.contact-item span {
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .content-box {
    padding: 20px;
    margin: 20px auto;
    width: 95%;
  }

  .content-box h2 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  .content-box p {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  .project-card {
    padding: 15px;
    margin: 15px 0;
  }

  .project-card h3 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .project-features {
    gap: 6px;
    margin: 12px 0;
    padding-left: 8px;
  }

  .project-features span {
    font-size: 0.85rem;
  }

  .project-link {
    padding: 6px 12px;
    font-size: 0.9rem;
    margin-top: 12px;
  }

  .contact-item span {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .content-box {
    padding: 15px;
    margin: 15px auto;
    width: 100%;
  }

  .content-box h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .content-box p {
    font-size: 0.85rem;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .project-card {
    padding: 12px;
    margin: 12px 0;
    border-radius: 10px;
  }

  .project-card h3 {
    font-size: 1.1rem;
    margin-bottom: 6px;
  }

  .project-features {
    gap: 5px;
    margin: 10px 0;
    padding-left: 6px;
  }

  .project-features span {
    font-size: 0.8rem;
  }

  .project-link {
    padding: 5px 10px;
    font-size: 0.85rem;
    margin-top: 10px;
    border-radius: 25px;
  }

  .more-projects {
    font-size: 0.85rem;
    margin-top: 15px;
  }

  .contact-item span {
    font-size: 13px;
  }
}

/* Add styles for very small screens */
@media (max-width: 360px) {
  .content-box {
    padding: 12px;
    margin: 12px auto;
  }

  .content-box h2 {
    font-size: 1.2rem;
  }

  .content-box p {
    font-size: 0.8rem;
  }

  .project-card {
    padding: 10px;
    margin: 10px 0;
  }

  .project-card h3 {
    font-size: 1rem;
  }

  .project-features span {
    font-size: 0.75rem;
  }

  .project-link {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}
  