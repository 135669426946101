/* Modern reset and base styles */
html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #111;
  color: white;
  overflow-x: hidden;
}

/* Main app container */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  padding: 40px 20px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}

/* Add some visual feedback during dragging */
.app-container.dragging-active {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Header and titles */
header {
  margin-bottom: 40px;
}

.main-title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 10px 0;
  background: linear-gradient(90deg, #ffffff, #cccccc);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
  opacity: 0.8;
}

/* Vinyl menu styling */
.vinyl-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px 0 40px 0;
}

.vinyl-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.vinyl-hint {
  margin-top: 10px;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.vinyl-wrapper:hover .vinyl-hint {
  opacity: 1;
}

/* Animated background */
.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #111, #1b2735, #192230);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  z-index: -1;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* View toggle button */
.view-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.view-toggle:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Mobile view styles */
.mobile-view {
  min-height: 100vh;
  width: 100%;
  padding: 80px 20px 40px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.mobile-content {
  max-width: 600px;
  margin: 0 auto;
}

.mobile-content header {
  text-align: center;
  margin-bottom: 40px;
}

.mobile-content .main-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.mobile-content .subtitle {
  font-size: 1.1rem;
  opacity: 0.8;
}

/* Vinyl menu styling for mobile */
.mobile-view .vinyl-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px 0 40px 0;
}

.mobile-view .vinyl-wrapper {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.mobile-view .vinyl-wrapper:active {
  transform: scale(0.95);
}

.mobile-view .vinyl-hint {
  margin-top: 10px;
  font-size: 1rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.mobile-view .vinyl-wrapper:hover .vinyl-hint {
  opacity: 1;
}

/* Contact content in modal */
.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contact-content .contact-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.contact-content .contact-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.contact-content .contact-item a {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.contact-content .contact-item a:hover {
  background: rgba(0, 184, 129, 0.2);
  transform: scale(1.1);
}

.contact-content .contact-item span {
  font-size: 1rem;
  opacity: 0.9;
}

/* Responsive styles */
@media (max-width: 768px) {
  .main-title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .vinyl-menu {
    flex-direction: column;
    align-items: center;
  }

  .mobile-content .main-title {
    font-size: 2rem;
  }

  .mobile-content .subtitle {
    font-size: 1rem;
  }

  .mobile-view .vinyl-menu {
    gap: 20px;
  }

  .mobile-view .vinyl-hint {
    font-size: 0.9rem;
  }
}

/* Loading animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app-container {
  animation: fadeIn 1s ease-out;
}