/* Center the entire record player */
.record-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    color: white;
    text-align: center;
    position: relative;
    transition: all 0.3s ease;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    max-width: 100%;
}

/* Drop active state */
.record-player-container.drop-active {
    background: rgba(0, 184, 129, 0.1);
    box-shadow: 0 0 30px rgba(0, 184, 129, 0.2);
}

/* Create the record player base */
.record-player {
    width: 300px;
    height: 300px;
    background: linear-gradient(145deg, #2d2d2d, #1a1a1a);
    border-radius: 50%;
    box-shadow: 
        0 10px 30px rgba(0, 0, 0, 0.7),
        inset 0 2px 8px rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 2rem;
    border: 8px solid #333;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.record-player:hover {
    box-shadow: 
        0 15px 40px rgba(0, 0, 0, 0.8),
        inset 0 2px 10px rgba(255, 255, 255, 0.15);
    transform: translateY(-5px);
}

/* Drop indicator */
.drop-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;
    animation: pulse 1.5s infinite;
}

.drop-indicator span {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-shadow: 0 0 10px rgba(0, 184, 129, 0.8);
}

@keyframes pulse {
    0% {
        background: rgba(0, 0, 0, 0.7);
    }
    50% {
        background: rgba(0, 184, 129, 0.3);
    }
    100% {
        background: rgba(0, 0, 0, 0.7);
    }
}

/* Stylized vinyl record */
.vinyl {
    width: 180px;
    height: 180px;
    background: radial-gradient(
        circle, 
        #111 10%, 
        #000 20%, 
        #222 40%, 
        #000 60%,
        #222 80%
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
}

.vinyl::after {
    content: "";
    width: 20px;
    height: 20px;
    background: #e0e0e0;
    border: 2px solid #ccc;
    border-radius: 50%;
    position: absolute;
}

/* Spin effect with smoother animation */
.spinning {
    animation: spin 4s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Improved control buttons */
.controls {
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    animation: fadeIn 0.5s ease-out;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.buttons button {
    background: linear-gradient(to bottom, #444, #333);
    color: white;
    border: none;
    padding: 12px 24px;
    margin: 10px 5px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 600;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    min-width: 80px;
    position: relative;
    overflow: hidden;
}

.buttons button:hover {
    background: linear-gradient(to bottom, #555, #444);
    transform: translateY(-2px);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

.buttons button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.buttons button.active {
    background: linear-gradient(to bottom, #00b881, #009e6e);
    box-shadow: 0 0 10px rgba(0, 184, 129, 0.5);
}

.buttons button.active:hover {
    background: linear-gradient(to bottom, #00d4a4, #00b881);
}

/* Now Playing UI */
.now-playing {
    margin-top: 20px;
    background: rgba(20, 20, 20, 0.5);
    padding: 15px;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.now-playing p {
    margin: 0 0 10px 0;
    font-weight: 500;
    color: #00b881;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background: #222;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px auto;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.8);
    position: relative;
}

.progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #00b881, #00d4a4);
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.1s linear;
    box-shadow: 0 0 10px rgba(0, 184, 129, 0.7);
}

.time-display {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 5px;
    opacity: 0.7;
}

/* Empty message */
.empty-message {
    margin-top: 20px;
    padding: 15px;
    background: rgba(20, 20, 20, 0.3);
    border-radius: 10px;
    animation: pulse 2s infinite;
}

.empty-message p {
    margin: 0;
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Responsive styles */
@media (max-width: 768px) {
    .record-player {
        width: 250px !important;
        height: 250px !important;
        margin: 1rem !important;
    }
    
    .vinyl {
        width: 150px !important;
        height: 150px !important;
    }
    
    .buttons {
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
    }
    
    .buttons button {
        padding: 8px 16px !important;
        font-size: 0.9rem !important;
        min-width: 70px !important;
    }
    
    .now-playing {
        padding: 10px;
    }
    
    .now-playing p {
        font-size: 0.9rem;
    }
    
    .time-display {
        font-size: 0.7rem;
    }
}

@media (max-width: 480px) {
    .record-player {
        width: 200px !important;
        height: 200px !important;
        margin: 0.5rem !important;
    }
    
    .vinyl {
        width: 120px !important;
        height: 120px !important;
    }
    
    .buttons button {
        padding: 6px 12px !important;
        font-size: 0.8rem !important;
        min-width: 60px !important;
    }
    
    .now-playing {
        padding: 8px;
    }
    
    .now-playing p {
        font-size: 0.8rem;
    }
    
    .time-display {
        font-size: 0.6rem;
    }
}