.vinyl-record {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  cursor: grab;
  position: relative;
  box-shadow: 
    0 5px 15px rgba(0, 0, 0, 0.5),
    inset 0 0 10px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}

.vinyl-record:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.vinyl-record:active,
.vinyl-record.pressed {
  transform: scale(0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.vinyl-record.dragging {
  opacity: 0.6;
  transform: scale(1.1);
  cursor: grabbing;
}

.vinyl-grooves {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(
    circle at center,
    transparent 0px,
    transparent 4px,
    rgba(0, 0, 0, 0.3) 5px,
    transparent 6px
  );
  z-index: 0;
}

.vinyl-label {
  width: 40%;
  height: 40%;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.vinyl-label span {
  color: #222;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .vinyl-record {
    cursor: pointer;
  }

  .vinyl-record:active,
  .vinyl-record.pressed {
    transform: scale(0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .vinyl-record.dragging {
    transform: scale(1.1);
    cursor: grabbing;
  }
} 