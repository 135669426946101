.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
  transition: opacity 0.3s ease-out;
}

.modal-overlay.closing {
  animation: fadeOut 0.3s ease-out forwards;
}

.modal-content {
  background: rgba(20, 20, 20, 0.95);
  border-radius: 20px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
  transition: opacity 0.3s ease-out;
}

.modal-content.closing {
  animation: fadeOut 0.3s ease-out forwards;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(90deg);
}

.modal-content h2 {
  font-size: 1.8rem;
  margin: 0 0 20px 0;
  background: linear-gradient(90deg, #ffffff, #cccccc);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-body {
  color: white;
  line-height: 1.6;
}

.modal-body p {
  margin-bottom: 15px;
  opacity: 0.9;
}

.modal-body p:last-child {
  margin-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 25px;
    width: 95%;
  }

  .modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .modal-body {
    font-size: 0.95rem;
  }
} 